import React, { useEffect, useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import DownloadAppModal from "./components/DownloadAppModal";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { Container } from "react-bootstrap";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import HelpPage from "./pages/HelpPage";
import NotFoundPage from "./pages/NotFoundPage";
import Footer from "./components/Footer";

function App() {
  const [downloadAppModalShow, setDownloadAppModalShow] = useState(false);

  const openDownloadAppModal = () => {
    setDownloadAppModalShow(true);
  };

  const closeDownloadAppModal = () => {
    setDownloadAppModalShow(false);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isiOS = /iPad|iPhone|iPod/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);

    if (isiOS || isAndroid) {
      openDownloadAppModal();
    }
  }, []);

  return (
    <div className="w-100 min-vh-100">
      <NavBar />
      <Container className="content">
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/terms" element={<TermsOfUsePage />}></Route>
          <Route path="/privacy" element={<PrivacyPolicyPage />}></Route>
          <Route path="/help" element={<HelpPage />}></Route>
          <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        <DownloadAppModal
          show={downloadAppModalShow}
          handleClose={closeDownloadAppModal}
          message="App available on: "
        />
      </Container>
      <Footer />
    </div>
  );
}

export default App;
