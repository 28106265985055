export const termsEn = `# GENERAL TERMS OF USE FOR VTIDES BY "MEDIAONE" EOOD (Mediaone EOOD)

Last updated: November 21, 2023

These Terms of Use are applicable to your access and use of: our mobile applications, all offered services, content, and functionalities. In these Terms, "Mediaone EOOD," "vtides," "we," "us," and "our" refer to "Mediaone EOOD" (Mediaone EOOD), "vtides," its successors and assigns; and "You" and "Your" refer to any user of our Services.

## Consent to these Terms

Your access and use of our Services are subject to these Terms and our Privacy Policy, located at vtides.com/terms, which is incorporated herein by reference. Please carefully review these Terms. By using the Services, you confirm that you are legally capable of entering into this agreement. By accessing or using our Services in any way or by clicking to accept or agree to the Terms when this option is made available to you, you agree to comply with these Terms. If you do not accept all of these terms and conditions, please do not access or use our Services.

These terms include a clause for mandatory individual arbitration and a waiver of class actions/judicial proceedings, which requires the use of arbitration on an individual basis to resolve disputes, rather than jury trials or class actions, and also limits the remedies available to you in the event of a dispute.

## Restriction on Use by Persons Under a Certain Age

Our Services are not intended for persons under the age of 14. If you are under 14 years of age, you are prohibited from registering an account or using the Services. Where required by law, persons between the ages of 14 and 18 may use the Services only with permission and under the supervision of a parent or legal guardian. If you do not meet all these criteria, you are prohibited from accessing or using the services.

## Updating the Terms

We may need to change these Terms from time to time at our discretion and without notice, in accordance with applicable law. The date of the last change will be noted at the beginning of these Terms. When we update these Terms, we will use appropriate methods to post the updated version in the Services. You are free to decide whether to accept an updated version of these Terms, but to continue accessing or using the Services, you must accept the updated Terms. If you do not accept these Terms or any updated version of these Terms, your only option is to terminate access to or use of the Services. Unless otherwise expressly stated by us, your access to and use of the services are deemed acceptance of the version of these terms valid at the time of your access or use. "Mediaone EOOD" (Mediaone EOOD) reserves the right to change these Terms at any time to reflect legislative and technical changes, as well as for other reasons at its discretion. It is the users' responsibility to regularly familiarize themselves with the current version of the Terms.

## Access and Account Protection

We are constantly developing our Services and introducing new Services regularly. This may lead to the addition, discontinuation, updating, or removal of features, products, content, or functionality, as well as the complete discontinuation of the Services. We reserve the right to take any of these actions at any time, and when we do, we will strive to notify you in advance, but this may not always be possible.

To access and use some of the Services, you may be required to provide certain information ("User Information") and create an account ("Account"). Our Privacy Policy governs the collection, use, storage, and disclosure of any personal information contained in the User Information. You affirm and warrant that all User Information you provide us periodically in connection with the Services is true, accurate, current, and complete. You agree to notify us immediately of any changes to the User Information by updating your account through the Applications.

You are responsible for any activity that occurs in your VTIDES account, whether with your permission or without it. Therefore, it is important to take appropriate measures to protect your account (e.g., use strong and unique passwords, keep your password secret, be careful when using public or shared devices). The company will not be liable for losses resulting from unauthorized use of your account or password, with or without your knowledge. You may be held liable for any losses incurred by the Company, its affiliates, employees, directors, officers, consultants, agents, and representatives as a result of unauthorized use of your account or password. Unless otherwise expressly stated in these Terms or required by applicable law, we are not responsible for losses or damages resulting from the loss or theft of your username, password, or other security information. If you believe someone has accessed your account, please contact support@vtides.com immediately.

## Intellectual Property

The Services and all related content, features, and functionalities (including, but not limited to, any information, software, text, graphics, images, video, and audio, as well as their design, selection, and organization) are owned by "Mediaone EOOD" (Mediaone EOOD), its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property laws or proprietary rights, as applicable.

According to these Terms, you have the right to access and use the services only for personal, non-commercial purposes.

The name VTIDES, the VTIDES logo, and all related names, logos, product and service names, designs, and slogans are trademarks of "Mediaone EOOD" (Mediaone EOOD). You must not use such marks without our prior written permission. You do not have the right to perform any of the following (or allow others to do so):
- Copying, archiving, downloading, uploading, distributing, indexing, broadcasting, presenting, displaying, printing, monitoring, making accessible, modifying, or otherwise using any part of the Services or the content of the Services, except as specified in these Terms;
- Using the Services, all tools provided by the Services, or any content in the Services for commercial purposes without obtaining our prior written permission; or
- Infringing or disregarding the copyrights, trademarks, or other intellectual property rights of "Mediaone EOOD" (Mediaone EOOD).

If you want to use our brand assets in a way not covered by these Terms, please contact us at support@vtides.com

If you violate these Terms, your right to access and use the Services will be immediately terminated, and you must, at our discretion, return or destroy all copies of the materials you have made. No right, title, or interest in or to the Services is transferred to You, and all rights not expressly granted are reserved by the Company. Any access or use of the Services not expressly authorized by these Terms is a violation of these Terms and may infringe copyright, trademark, and other laws.

## Restrictions on Use

The Services may only be used within legal frameworks and based on these current Terms. If your access to the Services or their use is prohibited by local law, you have no right to access or use the Services. We are not responsible if you use the Services in violation of applicable law. You agree not to use the Services:
- In a way that violates applicable local or international laws or regulations (including, without limitation, any laws restricting the export of data or software to and from other countries);
- In a way that violates the content standards specified in these Terms, in transmitting, knowingly receiving, uploading, downloading, using, or reusing any material that violates these Terms or applicable law;
- To impersonate or attempt to impersonate "Mediaone EOOD" (Mediaone EOOD), an employee of "Mediaone EOOD" (Mediaone EOOD), another user, or any other entity or organization (including, without limitation, by using email addresses or usernames associated with any of the aforementioned); or
- To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm VTIDES or users of the Services or expose them to liability.

You also agree not to:
- Use the Services in a way that could disable, overburden, damage, or impair the Services or interfere with any other party's use of the Services, including their ability to engage in real-time activities through the Services;
- Use any robot, spider, or other automatic devices, processes, or means to access the Services for any purpose, including monitoring or copying any of the materials available through the Services;
- Use any device, software, or routine that interferes with the proper functioning of the Services;
- Introduce viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;
- Attempt to gain unauthorized access to, interfere with, damage, or disrupt any part of the Services, the servers on which the Services are stored, or any server, computer, or database connected to the Services;
- Attack the Services via a denial-of-service attack or a distributed denial-of-service attack; or
- Attempt in any way to interfere with the proper functioning of the Services.

# Purchasing Products and Services

We offer products and services for purchase through the Apple App Store, Google Play, and other external platforms approved by "Mediaone EOOD" (Mediaone EOOD) (each an "External Platform"). All purchases made through an external platform will be processed through your account ("External Platform Account") in accordance with the terms presented to you at the time of purchase and the general terms and conditions applicable to your External Platform Account. Some external platforms may charge you sales tax depending on your place of residence, which may change. Any offer for a product or service made in our Services is void where prohibited.

## Fees in Connection with the Services

The Company may, now or in the future, charge subscription fees or usage fees for the Services or some features of the Services. You agree to pay all fees for products or services purchased on or through the Services at the prevailing prices and rates (including all applicable taxes) and to comply with the Company's payment terms in effect at the time of purchase. The Company may change the fees for using the Services or some features of the Services or introduce new fees at any time. In the event of a change in fees for products or services purchased through your account, the Company will notify you of such change before it takes effect (via a message in and/or through the Services).

## Subscriptions

You may be entitled to a free trial period for a subscription. If you register for a free trial and do not cancel it before the trial period expires, your trial may convert into a paid subscription, and the payment method you provided in your External Platform Account will be automatically charged. You will continue to be charged until you cancel your subscription through the External Platform Account methods indicated above in the Cancellation and Refund section. If you have previously taken advantage of a free trial subscription through the Apple Store or Google Play Store, you will not be entitled to another free trial – in this case, you will be automatically registered for a paid subscription and charged as described in these terms. If you purchase a subscription, it will automatically renew at the price you agreed to when subscribing until you cancel it, in accordance with the terms presented to you at the time of purchase and the general terms and conditions applicable to your External Platform Account. To cancel your subscription, you must log into your External Platform Account and follow the relevant instructions.
- You can follow these instructions to cancel your subscription on Apple.
- You can follow these instructions to cancel your subscription on Google. 

If you cancel your subscription, you will continue to have access to the benefits of your subscription until the end of the current subscription period, after which it will expire. Since our Services can be used without a subscription, canceling your subscription does not remove your profile from our Services. If you want to completely delete your profile, you must do so as indicated in our Privacy Policy.

## Use of Published Information

The information offered through the Services is provided solely for general informational purposes. We cannot guarantee the accuracy, completeness, or applicability of this information. Any action based on such information is taken entirely at your own risk. We assume no responsibility arising from any action based on such materials by you or any other user of the Services, or by anyone who may be informed of its content, presented through the Services. The Services may contain materials provided by third parties, including information provided by third-party licensors, aggregators, and/or analytics services. All statements and/or views presented in these materials, as well as all articles and responses to questions and other content, other than the content provided by "Mediaone EOOD" (Mediaone EOOD), are the sole responsibility of the individual or legal entity providing these materials. These materials do not necessarily reflect the views of "Mediaone EOOD" (Mediaone EOOD). We are not responsible to you or any third party for the content or accuracy of materials provided by third parties.

## Services and External Links

If the Services include links to third-party websites ("Linked Sites"), these links are provided for your convenience. Our inclusion of such links does not imply endorsement of the linked sites or association with their owners or operators, and we expressly disclaim any responsibility for such linked sites. We are not responsible for the content of linked sites and have no control over them. If you decide to visit linked sites, you do so at your own risk and subject to the terms of use of those linked sites. Any communication you have with linked sites is between you and the given site, and you agree that we are not responsible for any damages or losses you may incur as a result of such communications with linked sites or any claims you may have against the linked sites.

## Scope of the Services

We do not claim that the Services are available or appropriate outside the Republic of Bulgaria. Access to the Services may be illegal for some persons or in some countries. If you access the Services from outside the Republic of Bulgaria, you do so at your own risk and initiative and are responsible for compliance with the relevant local laws.

## Disclaimer of Warranties

WE ARE NOT LIABLE FOR ANY LOSSES OR DAMAGES ARISING FROM DISTRIBUTED DENIAL-OF-SERVICE ATTACKS, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIALS THAT MAY INFECT YOUR COMPUTER SYSTEMS, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL AS A RESULT OF USING THE SERVICES OR DOWNLOADING ANY MATERIALS POSTED ON IT OR ON ANY WEBSITE LINKED TO IT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED. WE, NOR ANY OF OUR AFFILIATES, MAKE ANY WARRANTIES OR REPRESENTATIONS REGARDING THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, WE, NOR ANY OF OUR AFFILIATES, MAKE ANY WARRANTIES OR REPRESENTATIONS THAT THE SERVICES WILL BE ACCURATE, RELIABLE, UNINTERRUPTED, OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL MEET YOUR NEEDS OR EXPECTATIONS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. THIS DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

# Limitation of Liability

IN NO EVENT SHALL WE, OUR AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND. WE ARE NOT LIABLE FOR DAMAGES UNDER ANY LEGAL THEORY. WE ARE NOT LIABLE FOR DAMAGES ARISING FROM OR IN CONNECTION WITH THE USE, OR INABILITY TO USE THE SERVICES OR INFORMATION, PRODUCTS, OR OTHER CONTENT (INCLUDING FROM THIRD PARTIES), INCLUDED IN OR ACCESSIBLE THROUGH THE SERVICES, INCLUDING, BUT NOT LIMITED TO DIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, WHETHER FORESEEN OR UNFORESEEN. WE ARE NOT LIABLE FOR DAMAGES INCLUDING, BUT NOT LIMITED TO PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA. WE ARE NOT LIABLE FOR ANY DAMAGES, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.

IN ADDITION TO AND WITHOUT LIMITATION TO THE FOREGOING, WE ARE NOT LIABLE FOR ANY FAILURES AND/OR LOSSES OF ANY KIND.

## Compensation

If you breach these Terms; use the Services, including but not limited to, your Social Content, any use of the content, services, and products of the Services other than as expressly permitted in these Terms; or if you use any information obtained from the Services in violation of these Terms, you agree to defend, indemnify, and (at our discretion) hold harmless "Mediaone EOOD," its affiliates, licensors, and service providers, as well as its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, and fees (including reasonable attorneys' fees). We reserve the right to take exclusive defense and control of any matter subject to indemnification by you, in which case you will cooperate with us in asserting any available defenses.

## Applicable Legislation

All matters relating to the Services and these Terms, as well as all disputes or claims arising from or related to them (including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the Republic of Bulgaria.

## Dispute Resolution

Dispute resolution through arbitration; Waiver of jury trial; Waiver of class action. For any and all disagreements, disputes, claims, or causes of action between you and us (including the interpretation and scope of this section and the possibility of arbitration of the dispute, disagreement, claim, or cause of action), related to the Services or these Terms (as well as any related or prior agreement you may have signed with us), you and we agree to resolve any such dispute, claim, or cause of action exclusively through binding and confidential arbitration. Arbitration is a method of resolving disputes outside of court. In the event of such a disagreement, dispute, claim, or cause of action, the party seeking to file a complaint must notify the other party in writing. Within 30 days of such notification, both parties agree to make reasonable efforts to try to resolve the dispute in good faith. If both parties do not resolve the dispute within 30 days after such notice, the complaining party must seek relief exclusively through arbitration. The request for arbitration must be filed within a reasonable time after the dispute, claim, or cause of action arises and in no event after the expiration of a two-year period from the moment the complaining party knew or should have known about the disagreement, dispute, claim, or cause of action.

The arbitration will be conducted at the AC at BCCI. The terms "we" and "us" in this section cover "Mediaone EOOD" and its subsidiaries, affiliates, predecessors, successors, and assigns, as well as all of their respective officers, directors, agents, and representatives. In addition, "we" and "us" include any third party providing a product, service, or benefit in connection with the Services or these Terms (as well as any related or prior agreement you may have signed with us), if such third party is named as a co-defendant with us in any dispute, disagreement, claim, or cause of action covered by this section.

In case of disagreements, any visitor who qualifies as a consumer under the Consumer Protection Act may refer the dispute for alternative resolution to the conciliation commission at the Consumer Protection Commission: [Consumer Protection Commission](https://kzp.bg/pomiritelna-komisiya) as well as file a complaint on the online dispute resolution website: [Online Dispute Resolution](https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=BG).

## Non-Waiver and Severability

Any failure by "Mediaone EOOD" to enforce any term or condition set forth in these Terms shall not be considered a waiver of such term or condition or a waiver of any other term or condition, and any failure of "Mediaone EOOD" to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.

## Communication

We will send you all notices and other communications regarding the Services using the contact information you provided when registering your account, which may be updated by you periodically, or by posting the notice within the Services. You can update your contact information by checking your account profile through the Services. Unless otherwise required by applicable law, you are deemed to have received a notice from us regarding the Services when we send it to the contact details available to us for you, or when we post such notice in the Services. All notices to us that are expected to have legal consequences must be in writing and delivered in a manner confirmed by receipt to the following address: "Mediaone EOOD," Bulgaria, Sofia 1000, Lavele Street 19, floor 4, office 5. All such notices are considered effective upon documented receipt by us.

## Entire Agreement and Additional Terms

These Terms, together with all rules or guidelines presented by us in the Applications, Websites, or in connection with the Service, constitute the entire agreement between You and "Mediaone EOOD" regarding the Services and supersede all prior and current understandings, agreements, representations, and warranties, both written and oral, regarding the Services. The rights under these Terms may not be transferred or delegated by You without our prior written approval, but may be transferred by us without restriction and without Your prior consent. Any attempt by You to transfer or delegate without our prior written consent will be null and void. Within these Terms, no agency, joint venture, partnership, fiduciary, or employment relationship is created between You and us. The section titles are provided for convenience only and will not have any legal effect. The invalidity of individual provisions of these General Terms and Conditions does not affect the validity of the remaining provisions.

## To contact us

[support@vtides.com](mailto:support@vtides.com)

`;
