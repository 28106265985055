import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import DownloadAppModal from "./DownloadAppModal";
import { Container, NavDropdown } from "react-bootstrap";
import useLanguage from "../hooks/useLanguage";

function NavBar() {
  const {
    language,
    changeLanguageToEnglish,
    changeSelectedLanguageToEnglish,
    changeLanguageToBulgarian,
    changeSelectedLanguageToBulgarian,
  } = useLanguage();

  const [downloadAppModalShow, setDownloadAppModalShow] = useState(false);

  const navigate = useNavigate();
  const openDownloadAppModal = () => {
    setDownloadAppModalShow(true);
  };

  const closeDownloadAppModal = () => {
    setDownloadAppModalShow(false);
  };

  return (
    <>
      <Navbar
        style={{ backgroundColor: "#454545" }}
        variant="dark"
        expand="sm"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid className="m-0 w-100">
          <div className="navBar-left">
            <Nav.Item style={{ paddingLeft: "4px" }}>
              <Nav.Link
                onClick={() => navigate(`/`)}
                eventKey="1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                }}
              >
                {language.home}
              </Nav.Link>
            </Nav.Item>
          </div>
          <Navbar.Toggle
            className="text-right"
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="mr-auto">
              <div className="navBar-right">
                <Nav.Item style={{ paddingLeft: "4px" }}>
                  <Nav.Link
                    onClick={() => navigate(`/terms`)}
                    eventKey="1"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    {language.termsOfUse}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ paddingLeft: "4px" }}>
                  <Nav.Link
                    onClick={() => navigate(`/privacy`)}
                    eventKey="1"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    {language.privacyPolicy}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ paddingLeft: "4px" }}>
                  <Nav.Link
                    onClick={() => navigate(`/help`)}
                    eventKey="1"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                    }}
                  >
                    {language.help}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className="navItem-buttons"
                  style={{ paddingLeft: "4px" }}
                >
                  <Nav.Link
                    onClick={() => openDownloadAppModal()}
                    eventKey="3"
                    style={{
                      color: "#454545",
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid white",
                      borderRadius: "5px",
                      padding: "10px 20px",
                    }}
                  >
                    {language.getTheApp}
                  </Nav.Link>
                </Nav.Item>
                <NavDropdown
                  style={{ paddingLeft: "0.5rem", color: "#373737" }}
                  title={language.language}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      changeLanguageToBulgarian();
                      changeSelectedLanguageToBulgarian();
                    }}
                  >
                    <img
                      src={require("../bulgarianFlag.png")}
                      width="20"
                      height="20"
                      className="d-inline-block align-top bg-light rounded"
                      alt="Bulgarian flag"
                    />{" "}
                    {language.bulgarian}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      changeLanguageToEnglish();
                      changeSelectedLanguageToEnglish();
                    }}
                  >
                    {" "}
                    <img
                      src={require("../englandFlag.png")}
                      width="20"
                      height="20"
                      className="d-inline-block align-top bg-light rounded"
                      alt="English flag"
                    />{" "}
                    {language.english}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <DownloadAppModal
        show={downloadAppModalShow}
        handleClose={closeDownloadAppModal}
        message={language.appAvailableOn}
      />
    </>
  );
}

export default NavBar;
