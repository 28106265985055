export const privacyEn = `# PRIVACY POLICY

These privacy policies have been adopted by **"MEDIAONE" EOOD** (Mediaone EOOD) and are effective from 11.2023, with the latest update: 21.11.2023.

## Protection of Your Personal Information

**"MEDIAONE" EOOD** (Mediaone EOOD) is committed to ensuring the protection of the personal data you provide to us. This Privacy Policy explains how we process, store, use, and disclose your information. This Privacy Policy applies to our mobile application VTIDES and all our corporate activities, collectively referred to as our services. By using our Services or providing us with information, you agree that this information may be collected, stored, used, and disclosed in accordance with this Privacy Policy.

## Contact

If you have any questions regarding this Privacy Policy, please contact us at [support@vtides.com](mailto:support@vtides.com).

## Consent to this Privacy Policy

This Privacy Policy supersedes all previous statements and notices regarding our privacy practices in connection with the Services. If you do not accept all the terms in this Privacy Policy, you are not authorized to access or use the Services.

We may update or change this Privacy Policy from time to time. If we introduce significant changes regarding this Privacy Policy or the way we collect, share, or use personal data, we will inform you by updating the "Effective Date" at the top of this Privacy Policy, by posting a notice of the changes in our Services in a visible place, or by sending you a notification using the contact information you have provided to us before the new terms take effect. Users are bound by all changes to the Privacy Policy if they continue to use our Services after being notified of such changes. We recommend that you regularly review this Privacy Policy to be informed of our current practices.

Furthermore, you acknowledge that this Privacy Policy is part of our General Terms and Conditions at [vtides.com/terms](https://vtides.com/terms) and is included therein by reference. By entering or using our Services, you agree to be bound by the Terms of Use. If you do not agree with our Terms of Use, do not access or use our Services.

## Information You Provide to Us

When using our Services, we acquire the information you send to us. Additionally, certain data is automatically collected from you. Some of this information may identify you ("Personal Data"), while other parts of this information may not identify you ("Non-Identifiable Data"). Sometimes non-identifiable data can be directly or indirectly linked or combined with personal data. In these cases, such non-identifiable data will be considered personal data for the purposes of this Privacy Policy.

Furthermore, to the extent that Internet Protocol addresses ("IP") or similar identifiers or information are considered data allowing personal identification, according to applicable law, we will treat them as personal data in accordance with this Privacy Policy.

## Information You Provide to Us

You may provide information including your name, email, mobile number, Facebook username, Apple App Store or Google Play date of birth, place of birth, and time of birth, as well as login name or account. If you purchase our Services or products, you may provide information about your credit or debit card. **"MEDIAONE" EOOD** (Mediaone EOOD) uses third parties to process payments – we do not acquire or retain credit card information.

If you provide your phone number, we send various types of transactional text messages directly related to the use of your Services, such as confirming your account and resetting your password. By providing your mobile phone number, you clearly agree to receive this type of transactional text messages from us at this number. Third-party data and message rates may apply.

## Information Automatically Collected by Devices

When you access our Services, we may automatically collect information related to your computer, phone, or other devices, including information related to your interaction with us, such as your IP address, operating system and version, time zone, date, time, and activity of your request, device type (e.g., desktop computer, laptop, tablet, phone, etc.), device manufacturer, screen size, and language preferences.

In line with the practices of most websites, we use cookies and pixel tags. Cookies are small text files used to collect information, and pixels are transparent images that help us understand how users interact with our websites. Our websites use necessary cookies and analytics cookies.

## Do Not Track

The "Do Not Track" ("DNT") feature is an option in your browser that allows you to express your preferences regarding tracking by advertisers and other third parties. We implement technology to recognize and comply with DNT settings that you have set in your browser.

## Use of Information

In general, we use the information we collect to provide you with the best possible services and to improve them over time.

Specifically, we may use anonymous information, as well as personal information, as stated elsewhere in this Privacy Policy, for the purpose of improving our Services, ensuring their proper operation, and for other research and commercial purposes. These purposes may include the following:

- Providing the Services and related notifications;
- Creating user accounts and/or profiles through registration and importing user contacts;
- Responding to your inquiries and/or requests regarding our Services and products;
- Managing, maintaining, analyzing, improving, and personalizing the Services and creating a better user experience;
- Providing or sending information about our Services;
- Sending transactional text messages directly related to the use of your Services (e.g., confirming your account and changing your password);
- Receiving feedback and providing customer and technical support for the Services;
- Conducting surveys, research, and audits;
- Fulfilling orders made through the websites (including processing your payment information, organizing delivery, and issuing invoices and/or order confirmations);
- Providing information to our representatives and/or advisors, such as lawyers, accountants, and others who help us comply with legal, accounting, or security requirements;
- Verifying and responding to your requests regarding your personal information or questions you may have regarding this Privacy Policy;
- Detecting, preventing, and investigating potential fraud, hacking, breaches, or other unacceptable behavior related to the Services; and for other purposes that have been disclosed at the time of collection.

In addition, we may use your personal information if we believe it is necessary or permitted by law to protect our business, our rights, or our property, or to address activities that may be illegal, unethical, or legally binding.

## Dissemination of Information

**"MEDIAONE" EOOD** (Mediaone EOOD) does not trade your personal information with third parties, nor does it provide personal information to third parties for direct marketing purposes.

We may provide non-personal information to third parties, as described in other parts of this Privacy Policy and for the purpose of improving our Services and for other commercial purposes.

We value your privacy and may only provide personal information in the following manner:

- To authorized business partners. Authorized business partners are third parties whose services **"MEDIAONE" EOOD** (Mediaone EOOD) uses to facilitate and improve our own Services. These third parties include agents, subcontractors, suppliers, system integrators, etc. **"MEDIAONE" EOOD** (Mediaone EOOD), our branches, and our business partners may exchange this personal information with each other and use it in accordance with this Privacy Policy. They may also combine it with other information to improve and offer our Services;
- To customer support employees. Authorized customer support employees may access your personal information if necessary to resolve issues you encounter in our Services and to help you use our Services;
- We may provide information about you for legal reasons, security, and safety considerations. We may provide information about you if we reasonably believe that providing the information is necessary to:
  - comply with any valid legal process, government request, or applicable law, rule, or regulation;
  - investigate, correct, or enforce potential violations of the General Terms and Conditions;
  - protect the rights, property, or safety of us, our users, or others;
  - detect and resolve any fraud or security problems.
- We may provide information about you as part of a merger or acquisition. If **"MEDIAONE" LTD** (Mediaone EOOD) is involved in a merger, sale of assets, financing, liquidation, or bankruptcy, or acquisition of all or part of our business by another company, we may provide your information to that company before and after the transaction is completed.

## Use of Social Media Features

### Login

You can log in to our Services using Facebook Connect, Apple, or Google Play. They will confirm your identity and give you the opportunity to provide us with certain personal information, such as your name and email, to automatically fill out our registration form.

## Protection, Storage, and Retention of Information

### Security

We have implemented adequate technical and organizational measures to protect to ensure the confidentiality, security, and integrity of your personal information from accidental or unlawful destruction, loss, alteration, disclosure, or unauthorized access. However, we cannot guarantee the security of the information sent to us over the Internet.

### Storage

The personal information you provide to us is usually stored on servers managed by AWS or Amazon Web Services. These servers are located in Frankfurt, Federal Republic of Germany. If you are in another jurisdiction, you should know that once your personal information is sent through our Services, it will be transferred to our servers in Frankfurt, Federal Republic of Germany, and therefore will be subject to local data protection laws, which may vary in different countries.

### Retention

We will store your personal information for the period necessary to fulfill the purposes described in this Privacy Policy unless the law requires or allows a longer storage period.

## International Users

Our company is based in the Republic of Bulgaria, and we store personal information on servers located in Frankfurt, Federal Republic of Germany, which may not have the same data protection laws as the country where you live. If you use our services outside the Republic of Bulgaria, your information may be transferred, stored, and processed in Frankfurt, Federal Republic of Germany. By using our services, you agree to such transfer of your information.

We may act as a "data controller" as defined in applicable data protection legislation when processing your information from the website, applications, and services. In general, we rely on our legitimate interests when processing this information. These legitimate interests include providing our services, managing our website and applications, and other business purposes. We may also process your personal information to fulfill contracts we may have with you. If consent is the legal basis, we will obtain your consent and inform you how you can withdraw it.

## Your Options and Rights

If you wish to update your contact details or preferences, opt out of our data lists, or have comments or questions regarding this Privacy Policy, please contact us at [support@vtides.com](mailto:support@vtides.com).

It is not mandatory to provide us with your data. If you refuse to do so, we may not be able to respond to your requests or questions, and without specific information such as your date of birth, place of birth, and time of birth, some or all of the features of the Services may not be available or may not function properly.

You can stop receiving email messages at any time by clicking on the unsubscribe link in any email you receive.

If you reside or are located in certain jurisdictions, you may have rights and protections regarding your information under applicable laws. These rights may include the right to access, delete, correct, transfer (or port), object to processing, and restrict the processing of your information, as well as the right to file a complaint with a regulator. These rights are not absolute, there are exceptions, and we may be required or allowed by law to reject your request. Within the requirements and permissions of applicable laws, we will process your request and, if applicable, may request additional information to confirm your identity. If you want to exercise your rights or have questions about how we process your personal information, please contact us at [support@vtides.com](mailto:support@vtides.com).

## Changes to this Privacy Policy

We may periodically update or change this Privacy Policy and will inform you of the changes by updating the date of the last update at the top of this Privacy Policy, posting a notice of the changes in a visible place in our Service, or sending you a written notice using the contact information you have provided to us before the new policy takes effect. Users are bound by all changes to the Privacy Policy if they continue to use our websites, applications, and services after receiving notice of such changes. We recommend that you check this Privacy Policy regularly to stay up to date with our current practices.
`;
