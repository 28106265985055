import React from "react";
import ReactMarkdown from "react-markdown";
import "./container.css";
import useLanguage from "../hooks/useLanguage";

export default function PrivacyPolicyPage() {
  const { privacyPolicy } = useLanguage();

  return (
    <div className="privacyPolicyContainer">
      <ReactMarkdown children={privacyPolicy} />
    </div>
  );
}
