import React from "react";
import Navbar from "react-bootstrap/Navbar";
import useLanguage from "../hooks/useLanguage";

function Footer() {
  const { language } = useLanguage();

  return (
    <Navbar
      fixed="bottom"
      style={{
        backgroundColor: "#454545",
        justifyContent: "center",
      }}
      variant="dark"
    >
      <Navbar.Brand
        style={{
          display: "flex",
          width: "50%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "medium",
        }}
      >
        {language.allRightsReserved}
      </Navbar.Brand>
    </Navbar>
  );
}

export default Footer;
