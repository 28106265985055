import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./container.css";
import useLanguage from "../hooks/useLanguage";

function HomePage() {
  const { selectedLanguage } = useLanguage();
  //TODO: add links for apps in stores
  return (
    <div className="home-container">
      <Container className="d-flex text-center justify-content-center align-items-center">
        <Row className="home-row">
          <Col>
            <a href="">
              <Image
                src={require(selectedLanguage === "bg"
                  ? "../bg-btn-apple-store.png"
                  : "../en-btn-apple-store.png")}
                alt="Apple store download button"
                fluid
                style={{ height: "100%", width: "50%" }}
              />
            </a>
            <a href="">
              <Image
                src={require(selectedLanguage === "bg"
                  ? "../bg-btn-google-play.png"
                  : "../en-btn-google-play.png")}
                alt="Google play store download button"
                fluid
                style={{ height: "100%", width: "50%" }}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomePage;
