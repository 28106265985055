export const languages = {
  en: {
    home: "Home",
    termsOfUse: "Terms",
    allRightsReserved: "© Mediaone EOOD All rights reserved",
    login: "Login",
    downloadApp: "Download the App for free:",
    downloadOnApp: "Download on the App Store",
    downloadOnGoogle: "Download on Google Play",
    help: "Help",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send",
    language: "Language",
    bulgarian: "Bulgarian",
    english: "English",
    privacyPolicy: "Privacy",
    getTheApp: "Get the App",
    appAvailableOn: "App available on App Store and Google Play Store",
    messageSent: "Message Sent",
  },
  bg: {
    home: "Начало",
    termsOfUse: "Условия",
    allRightsReserved: "© Mediaone EOOD 2023 Всички права запазени",
    downloadApp: "Свалете приложението безплатно:",
    downloadOnApp: "Свалете от App Store",
    downloadOnGoogle: "Свалете от Google Play",
    help: "Помощ",
    name: "Име",
    email: "Имейл",
    message: "Съобщение",
    send: "Изпрати",
    language: "Език",
    bulgarian: "Български",
    english: "Английски",
    privacyPolicy: "Правила",
    getTheApp: "Свалете приложението",
    appAvailableOn: "Апликацията е достъпна в App Store и Google Play Store",
    messageSent: "Съобщението е изпратено",
  },
};
