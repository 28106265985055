export const privacyBg = `# ПОЛИТИКА ЗА ПОВЕРИТЕЛНОСТ

Тези политики за поверителност са приети от „МЕДИЯУАН“ ЕООД (Mediaone EOOD) и са в сила от 11.2023 г., с последна актуализация: 21.11.2023 г.

## Защита на вашата лична информация
„МЕДИЯУАН“ ЕООД (Mediaone EOOD) се ангажира да гарантира защитата на личните данни, които ни предоставяте. Тази Политика за поверителност обяснява как обработваме, съхраняваме, използваме и разкриваме информацията ви. Тази Политика за поверителност се отнася за нашето мобилно приложение VTIDES и за всички наши корпоративни дейности, наричани заедно нашите услуги. Използвайки нашите Услуги или ни предоставяйки информация, вие се съгласявате тази информация да бъде събирана, съхранявана, използвана и разкривана според тази Политика за поверителност.

## Контакт
Ако имате въпроси относно тази Политика за поверителност, свържете се с нас на support@vtides.com.

## Съгласие с тази политика за поверителност
Тази Политика за поверителност заменя всички предходни изявления и уведомления относно нашите практики за поверителност във връзка с Услугите. Ако не приемате всички условия в тази Политика за поверителност, не сте упълномощени да имате достъп или да използвате Услугите.

Може да актуализираме или променяме тази Политика за поверителност от време на време. Ако въведем съществени промени по отношение на тази Политика за поверителност или начина, по който събираме, споделяме или използваме лични данни, ще ви информираме, като обновим „Датата на влизане в сила“ най-горе в тази Политика за поверителност, като публикуваме известие за промените в нашите Услуги на видимо място или като ви изпратим уведомление, използвайки контактната информация, която сте ни предоставили, преди новите условия да влязат в сила. Потребителите са обвързани от всички промени в Политиката за поверителност, ако продължат да използват нашите Услуги след уведомяване за такива промени. Препоръчваме ви редовно да преглеждате тази Политика за поверителност, за да бъдете запознати с текущите ни практики.

Освен това вие признавате, че тази Политика за поверителност е част от нашите Общи условия на vtides.com/terms и е включена в тях чрез препратка. С влизането или използването на нашите Услуги вие приемате да бъдете обвързани с Условията за ползване. Ако не сте съгласни с нашите Условия за ползване, не влизайте и не използвайте нашите Услуги.

## Информация, която ни представяте

### При ползване на нашите Услуги
При ползване на нашите Услуги, ние придобиваме информацията, която ни изпращате. Допълнително, определени данни се събират автоматично от вас. Някоя от тази информация може да ви идентифицира („Персонални данни“), а друга част от тази информация може да не ви идентифицира („Неидентифицируеми данни“). Понякога неидентифицируемите данни могат директно или индиректно да бъдат свързани или комбинирани с персонални данни. В тези случаи такива неидентифицируеми данни ще бъдат считани за персонални данни за целите на настоящата Политика за поверителност. Освен това, до степента, в която адресите на интернет протокола („IP“) или подобни идентификатори или информация се считат за данни, позволяващи лично идентифициране, съгласно приложимото законодателство, ние ще ги третираме като персонални данни съгласно настоящата Политика за поверителност.

### Информация, която ни представяте
Можете да предоставите информация, включително вашето име, имейл, мобилен номер, потребителско име във Facebook, Apple App Store или Google Play, дата на раждане, местоположение на раждане и час на раждане, както и име за вход или акаунт. Ако закупите нашите Услуги или продукти, можете да предоставите информация за вашата кредитна или дебитна карта. „МЕДИЯУАН“ ЕООД (Mediaone EOOD) използва трети страни за обработка на плащания – ние не придобиваме и не запазваме информация за кредитни карти. Ако предоставите вашия телефонен номер, ние изпращаме различни видове транзакционни текстови съобщения, директно свързани с ползването на вашите Услуги, като потвърждаване на вашия акаунт и повторно задаване на паролата ви. Като предоставяте своя мобилен телефонен номер, вие ясно се съгласявате да получавате този вид транзакционни текстови съобщения от нас на този номер. Могат да се прилагат такси за данни и съобщения на трети страни.

# Информация, събрана автоматично от уреди

При вашето влизане в нашите Услуги, можем автоматично да съберем информация, свързана с вашия компютър, телефон или друго устройство, включително информация, свързана с вашето взаимодействие с нас, като вашия IP адрес, операционна система и версия, часова зона, дата, час и активност на вашата заявка, тип устройство (напр. настолен компютър, лаптоп, таблет, телефон и др.), производител на устройството, размер на екрана и езикови предпочитания.

Съобразно с практиките на повечето уебсайтове, ние използваме бисквитки и пикселни етикети. Бисквитките са малки текстови файлове, използвани за събиране на информация, а пикселите са прозрачни изображения, които ни помагат да разберем как потребителите взаимодействат с нашите уебсайтове. Нашите уебсайтове използват необходими бисквитки и бисквитки за анализ.

## Не следи
Функцията „Не следи“ („DNT“) е опция във вашия браузър, която ви дава възможност да изразите вашите предпочитания относно проследяването от рекламодатели и други трети страни. Ние прилагаме технология за разпознаване и спазване на DNT настройките, които сте задали във вашия браузър.

## Използване на информацията

По принцип ние използваме информацията, която събираме, с цел да ви предоставим най-добрите възможни услуги и да ги подобряваме с течение на времето. По-специално, можем да използваме анонимна информация, както и лична информация, както е посочено другаде в тази Политика за поверителност, с цел подобряване на нашите Услуги, гарантиране на правилната им работа и за други изследователски и комерсиални цели. Тези цели могат да включват следното:

- Предоставяне на Услугите и свързаните с тях известия;
- Създаване на потребителски акаунти и/или профили чрез регистрация и импортиране на потребителски контакти;
- Отговаряне на ваши запитвания и/или заявки относно нашите Услуги и продукти;
- Управление, поддръжка, анализ, подобрение и персонализация на Услугите и създаване на по-добро потребителско преживяване;
- Предоставяне или изпращане на информация за нашите Услуги;
- Изпращане на транзакционни текстови съобщения, директно свързани с използването на вашите Услуги (напр. потвърждаване на вашия акаунт и смяна на вашата парола);
- Получаване на обратна връзка и предоставяне на клиентска и техническа поддръжка за Услугите;
- Провеждане на проучвания, изследвания и одити;
- Изпълнение на поръчки, направени през уебсайтовете (включително обработка на информацията за вашите плащания, организация на доставка и издаване на фактури и/или потвърждения за поръчки);
- Предоставяне на информация на нашите представители и/или съветници, като адвокати, счетоводители и други, които ни помагат да спазваме законовите, счетоводните или изискванията за сигурност;
- Проверка и отговор на вашите искания относно вашата лична информация или въпроси, които може да имате относно тази Политика за поверителност;
- Откриване, предотвратяване и разследване на потенциално измама, хакерство, нарушение или друго неприемливо поведение, свързано с Услугите; и за други цели, които са били разкрити по време на събирането.

Освен това можем да използваме вашата лична информация, ако считаме, че е необходимо или е разрешено по закон, за защита на нашия бизнес, нашите права или нашата собственост, или за справяне с дейности, които могат да бъдат незаконни, неетични или обвързващи по закон.

## Разпространение на информация

„МЕДИЯУАН“ ЕООД (Mediaone EOOD) не търгува с вашата лична информация на трети страни, нито предоставя лична информация на трети страни с цел директен маркетинг. Можем да предоставяме нелична информация на трети страни, както е описано в други части на тази Политика за поверителност и с цел подобряване на нашите Услуги и за други комерсиални цели.

Ние ценим Вашата поверителност и можем да предоставим лична информация само по следния начин:

- До упълномощени бизнес партньори. Упълномощените бизнес партньори са трети страни, чиито услуги „МЕДИЯУАН“ ЕООД (Mediaone EOOD) използва, за да улесни и подобри нашите собствени Услуги. Тези трети страни включват агенти, подизпълнители, доставчици, системни интегратори и др. „МЕДИЯУАН“ ЕООД (Mediaone EOOD), нашите клонове и нашите бизнес партньори могат да обменят тази лична информация помежду си и да я използват в съответствие с тази Политика за поверителност. Те могат също да я комбинират с друга информация, за да подобрят и предлагат нашите Услуги;
- На служители за поддръжка на клиенти. Упълномощените служители за поддръжка на клиенти могат да получат достъп до вашата лична информация, ако е необходимо, за да разрешат проблеми, които срещате в нашите Услуги, и да ви помогнат да използвате нашите Услуги;
- Може да предоставим информация за вас от правни съображения, съображения за безопасност и сигурност. Можем да предоставим информация за вас, ако основателно смятаме, че предоставянето на информацията е необходимо за:
  - спазване на всеки валиден правен процес, правителствено искане или приложим закон, правило или наредба;
  - разследване, коригиране или налагане на потенциални нарушения на Общите условия;
  - защита на правата, собствеността или безопасността на нас, нашите потребители или други;
  - откриване и разрешаване на всякакви измама или проблеми със сигурността.
- Може да предоставим информация за вас като част от сливане или придобиване. Ако „МЕДИЯУАН“ ЕООД (Mediaone EOOD) участва в сливане, продажба на активи, финансиране, ликвидация или фалит, или придобиване на целия или част от нашия бизнес от друга компания, ние може да предоставим вашата информация на тази компания преди и след завършване на транзакцията.

# Използване на функциите на социалните медии

## Вход

Можете да влезете в нашите Услуги с помощта на Facebook Connect, Apple или Google Play. Те ще потвърдят вашата идентичност и ще ви дадът възможността да ни предоставите определена лична информация, като вашето име и електронна поща, за да автоматично попълним нашата регистрационна форма.

## Защита, съхранение и задържане на информация

### Сигурност

Ние сме въвели адекватни технически и организационни мерки за защита, за да гарантираме поверителността, сигурността и целостта на вашата лична информация от случайно или незаконно унищожаване, загуба, промяна, разкриване или неоторизиран достъп. Обаче, не можем да гарантираме сигурността на информацията, изпратена до нас по интернет.

### Съхранение

Личната информация, която ни предоставяте, обикновено се съхранява на сървъри, управлявани от AWS или Amazon Web Services. Тези сървъри се намират във Франкфурт, Федерална република Германия. Ако сте в друга юрисдикция, трябва да знаете, че след като вашата лична информация бъде изпратена чрез нашите Услуги, тя ще бъде прехвърлена към нашите сървъри във Франкфурт, Федерална република Германия и следователно ще бъде подложена на местните закони за защита на данните, които могат да варират в отделните държави.

### Задържане

Ние ще съхраняваме вашата лична информация за периода, необходим за изпълнение на целите, описани в тази Политика за поверителност, освен ако законът не изисква или позволява по-дълъг период на съхранение.

## Международни потребители

Нашата компания е базирана в Р. България и ние съхраняваме лична информация на сървъри, разположени във Франкфурт, Федерална република Германия, които може да нямат същите закони за защита на данните като държавата, в която живеете. Ако използвате нашите услуги извън Р. България, вашата информация може да бъде прехвърлена, съхранявана и обработвана във Франкфурт, Федерална република Германия. Чрез използването на нашите услуги, вие се съгласявате с такова прехвърляне на информацията си.

Можем да действаме като „администратор на данни“, както е определено в приложимото законодателство за защита на данните, при обработката на вашата информация от уебсайта, приложенията и услугите. В общи линии, ние разчитаме на нашите законни интереси, когато обработваме тази информация. Тези законни интереси включват предоставянето на нашите услуги, управлението на нашия уебсайт и приложения и други бизнес цели. Може също така да обработваме вашата лична информация, за да изпълним договорите, които можем да имаме с вас. Ако съгласието е правното основание, ние ще получим вашето съгласие и ще ви информираме как можете да го оттеглите.

## Вашите опции и права

Ако желаете да обновите вашите контактни данни или предпочитания, искате да бъдете изключени от нашите списъци с данни или имате коментари или въпроси относно тази Политика за поверителност, моля свържете се с нас на [support@vtides.com](mailto:support@vtides.com).

Не е задължително да ни предоставяте вашите данни. Ако откажете да го направите, можем да не сме в състояние да отговорим на вашите заявки или въпроси и без конкретна информация като вашата дата на раждане, място на раждане и час на раждане, някои или всички функции на Услугите може да не са налични или да не функционират както трябва.

Можете да спрете да получавате имейл съобщения по всяко време, като кликнете върху връзката за отписване във всеки имейл, който получавате.

Ако пребивавате или се намирате в определени юрисдикции, може да имате права и защита относно вашата информация съгласно приложимите закони. Тези права могат да включват правото на достъп, изтриване, коригиране, прехвърляне (или пренос), възражение срещу обработка и ограничаване на обработката на вашата информация, както и правото да подадете жалба до регулатор. Тези права не са абсолютни, има изключения и може да ни се изисква или позволи по закон да отхвърлим вашата заявка. В рамките на изискванията и разрешенията на приложимите закони, ще обработим вашата заявка и ако е приложимо, може да поискаме допълнителна информация, за да потвърдим самоличността ви. Ако искате да упражните правата си или имате въпроси относно начина, по който обработваме вашата лична информация, моля, свържете се с нас на [support@vtides.com](mailto:support@vtides.com).

## Промени в тази политика за поверителност

Можем да актуализираме или променяме тази Политика за поверителност периодично и ще ви информираме за промените, като обновим датата на последното обновление в горната част на тази Политика за поверителност, публикуваме съобщение за промените на видно място в нашата Услуга или ви изпратим писмено известие, използвайки контактната информация, която сте ни предоставили, преди новата политика да влезе в сила.

**Потребителите са обвързани от всички промени в Политиката за поверителност, ако продължат да използват нашите уебсайтове, приложения и услуги след като са получили известие за такива промени. Препоръчваме ви да проверявате тази Политика за поверителност редовно, за да сте в курс с нашите актуални практики.**

`;
